import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { DeepLinkBanner } from "../types/DeepLinkBanner";

type DeepLinkBannerState = {
  deepLinkBanners?: DeepLinkBanner[];
  getDeepLinkBanner: (placement: string) => DeepLinkBanner | undefined;
  getDeepLinkBanners: (placement: string) => DeepLinkBanner[] | undefined;
  setDeepLinkBanners: (deepLinkBanners?: DeepLinkBanner[]) => void;
};

const useDeepLinkBannerStore = create<DeepLinkBannerState>()(
  persist(
    (set, get) => ({
      deepLinkBanners: [],
      getDeepLinkBanner: (placement) =>
        get().deepLinkBanners?.find((adUnit) => adUnit.placement === placement),
      getDeepLinkBanners: (placement) =>
        get().deepLinkBanners?.filter(
          (adUnit) => adUnit.placement === placement,
        ),
      setDeepLinkBanners: (deepLinkBanners) => set({ deepLinkBanners }),
    }),
    {
      name: "deeplinkbanner-storage",
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);

export default useDeepLinkBannerStore;
