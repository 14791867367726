import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { ContentLinkBanner } from "../types/ContentLinkBanner";

type ContentLinkBannerState = {
  contentLinkBanners?: ContentLinkBanner[];
  getContentLinkBanner: (placement: string) => ContentLinkBanner | undefined;
  getContentLinkBanners: (placement: string) => ContentLinkBanner[] | undefined;
  setContentLinkBanners: (deepLinkBanners?: ContentLinkBanner[]) => void;
};

const useContentLinkBannerStore = create<ContentLinkBannerState>()(
  persist(
    (set, get) => ({
      contentLinkBanners: [],
      getContentLinkBanner: (placement) =>
        get().contentLinkBanners?.find(
          (adUnit) => adUnit.placement === placement,
        ),
      getContentLinkBanners: (placement) =>
        get().contentLinkBanners?.filter(
          (adUnit) => adUnit.placement === placement,
        ),
      setContentLinkBanners: (deepLinkBanners) =>
        set({ contentLinkBanners: deepLinkBanners }),
    }),
    {
      name: "contentlinkbanner-storage",
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);

export default useContentLinkBannerStore;
