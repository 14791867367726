import { create } from "zustand";

type EnvState = {
  env: string;
  sentryDsn: string;
  isOnline: boolean;
  closedSdkVersion: string;
  adsPlacementQueueTimeout: number;
  setEnv: (env: string) => void;
  setSentryDsn: (sentryDsn: string) => void;
  setIsOnline: (isOnline: boolean) => void;
  setClosedSdkVersion: (closedSdkVersion: string) => void;
  setAdsPlacementQueueTimeout: (queueTimeout: number) => void;
};

const useEnvStore = create<EnvState>()((set, get) => ({
  env: "",
  sentryDsn: "",
  isOnline: true,
  closedSdkVersion: "",
  adsPlacementQueueTimeout: 5000,
  setEnv: (env) => set({ env }),
  setSentryDsn: (sentryDsn) => set({ sentryDsn }),
  setIsOnline: (isOnline) => set({ isOnline }),
  setClosedSdkVersion: (closedSdkVersion) => set({ closedSdkVersion }),
  setAdsPlacementQueueTimeout: (adsPlacementQueueTimeout) =>
    set({ adsPlacementQueueTimeout }),
}));

export default useEnvStore;
